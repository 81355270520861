@use "sass:color";
@use 'sass:math';

// Named couleurs
$blueNoty: #3F78BB;
$gainsboro: #DCDCDC;
$greenNoty: #27B3A2;
$GreyOfDavy: #555;
$whiteSmoke: #F5F5F5;

// Thème couleurs
$danger: #D43F3A;
$green: #1FBBA6;
$grey: #C8C7CC;
$greyLight: #E2E2E4;
$orange: #F60;
$pink: #DD5A82;
$pinkDark: color.adjust($pink, $lightness: -10%);
$primary: #007AFF; // @todo utiliser $blueNoty;
$success: #5CB85C;
$textColor: #8E8E93;
$textDark: color.adjust($textColor, $lightness: -20%);
$textMutedColor: color.adjust($textColor, $lightness: 10%);
$warning: #eea236;

// Thème autre
$border-separator: 1px solid $gainsboro;
$container-padding-side: 30px;
$font-size-base: 13px;
$font-size-extra-large: math.floor($font-size-base * 1.35);

// API couleurs
$colorBlack: #707070;
$colorGrey1: #D3CDC5;
$colorGrey2: #BBB6AF;
$colorGrey3: #A09C96;
$colorGrey4: #7C7974;
$colorGrey5: #696763;
$colorRed1: #AE5C5E;
$colorRed2: #AE3F47;
$colorRed3: #AE242B;
$colorRed4: #C12A1C;
$colorRed5: #D50A34;
$colorOrange1: #EEAB7F;
$colorOrange2: #EE9C51;
$colorOrange3: #EE7A21;
$colorOrange4: #EE5F06;
$colorOrange5: #EE4607;
$colorYellow1: #D8C15F;
$colorYellow2: #D8C82D;
$colorYellow3: #D8BA1B;
$colorYellow4: #D8A516;
$colorYellow5: #D89800;
$colorGreen1: #AABF51;
$colorGreen2: #48A444;
$colorGreen3: #46A888;
$colorGreen4: #357D65;
$colorGreen5: #247D4E;
$colorBlue1: #93C8C6;
$colorBlue2: #47AAAA;
$colorBlue3: #478AAD;
$colorBlue4: #4666AE;
$colorBlue5: #34328A;
$colorPurple1: #7D358B;
$colorPurple2: #4F3689;
$colorPurple3: #5E4889;
$colorPurple4: #7D4A89;
$colorPurple5: #89486B;

// Fonts family
$font-family-base: Inter, sans-serif;
$font-family-default: $font-family-base;

// Tailwind
$tw-min-sm: 640px;
$tw-min-md: 768px;
$tw-min-lg: 1024px;
$tw-min-xl: 1280px;
$tw-min-2xl: 1536px;

/////////////////////////
// VARIABLES BOOTSTRAP //
/////////////////////////
// @use "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables" as bootstrap;

$font-size-base: 14px !default;
$font-size-small: math.ceil($font-size-base * .85) !default; // ~12px

$screen-lg: 1200px !default;
$screen-md: 992px !default;
$screen-sm: 768px !default;
$screen-lg-min: $screen-lg !default;
$screen-md-min: $screen-md !default;
$screen-sm-min: $screen-sm !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-xs-max: ($screen-sm-min - 1) !default;
