@use "tailwind";
@use "app/tw-app";
@use "app/app-tree";

ul,
ul[class*=tw\:] {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bg-modules-fond {
  background-image: url("/assets/images/modules/background.svg");
}

textarea {
  resize: none;
}

@media print {
  *,
  *:before,
  *:after {
    color-adjust: exact;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .tw\:print\:overflow-initial {
    overflow: initial;
  }
}
