@use "../../../../variables" as variables;
@use "../variables" as app_variables;
@use "eskimmo/dashboard";
@use "eskimmo/negociation";

.calculator {
  .calculator-form {
    .types-ventes {
      .radio-inline {
        .radio {
          margin: 0 !important;
        }
      }
    }
  }

  .calculator-display {
    .calculator-col:first-child {
      border-right: variables.$border-separator;
      padding-bottom: 20px;
    }

    .calculator-col {
      .calculator-line {
        margin-top: 5px;

        span {
          color: variables.$green;
        }
      }
    }
  }
}

.barre-faire {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 52px;
  padding: 10px 0;
  background: rgba(variables.$greenNoty, 0.9);
  color: app_variables.$appBackground;

  .dossiers-select-label {
    float: left;
    padding-top: 5px;
    padding-left: 15px;
    font-size: app_variables.$font-size-large;

    @media (max-width: app_variables.$screen-xs-max) {
      display: none;
    }
  }

  .dossiers-select-actions {
    float: left;
    padding-left: 15px;

    .loading {
      margin: 0;
      padding-top: 5px;
    }

    .actions {
      button {
        margin-right: 3px;
        background-color: white;
        border-color: rgba(variables.$greenNoty, 0.9);
        color: rgba(variables.$greenNoty, 0.9);

        &.btn-dropdown {
          margin-right: 0;
        }

        @media (max-width: app_variables.$screen-xs-max) {
          .btn-label {
            display: none;
          }
        }
      }
    }
  }
}

.dossier-selection {
  .dossier-selected {
    span:first-child {
      margin-left: 0;
    }

    span {
      width: initial;
      margin-left: 5px;
    }
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-task-runner {
  .popover {
    max-width: 400px;
    width: 400px;

    .task-runner {
      .upload-files {
        .upload-file {
          margin-top: 10px;

          a {
            color: variables.$primary;
          }

          .process-status {
            .progress-striped {
              margin: 0;
            }
          }

          button {
            padding: 0;
            border: 0;
            color: variables.$danger;
          }
        }

        .upload-file-more {
          margin-top: 10px;
        }
      }
    }
  }
}

esk-upload-files-info:where([class*=tw\:]) {
  .upload-files {
    fieldset.files-infos-process {
      background: rgb(249 250 251);
      margin: 1rem 0 0;
    }
  }

  .alert {
    margin: 1rem 0 0;
  }
}

.upload-files {
  .files-infos-process {
    .file-process {
      margin-top: 5px;

      .file-process-status {
        .progress-striped {
          margin: 0;
        }
      }

      button {
        padding: 0;
        border: 0;
        color: variables.$danger;
      }
    }
  }

  .file-process-more {
    margin-top: 5px;
  }

  .alert {
    button {
      padding: 0;
      border: 0;
      color: variables.$danger;
    }

    .popover {
      max-width: 400px;
    }

    .files-errors {
      .file-process {
        margin-top: 5px;

        .file-process-status {
          a,
          div {
            display: inline;
          }
        }
      }
    }
  }
}

esk-contact {
  .send {
    padding: 0;
    background: 0;
    border: 0;
    color: variables.$primary;
    top: -1px;
    font-size: inherit;

    &:hover {
      color: #0062cc;
    }
  }
}

.container-fluid-title {
  padding-left: 0;
  padding-right: 0;

  .row {
    background-color: app_variables.$snow;

    @media (min-width: app_variables.$screen-sm-min) {
      height: 68px;
    }

    h3 {
      font-size: app_variables.$font-size-mega-large;
      padding-top: 4px;
    }

    p {
      padding-top: 4px;
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: app_variables.$font-size-giga-large;
    font-weight: bold;
    padding-top: 10px;
    display: inline-block;
  }
}

.menu-two-part {
  padding: 0;

  .menu-item {
    background-color: app_variables.$lightGrey;
    padding: 15px 0;
    display: inline-block;
    text-align: center;

    a {
      color: app_variables.$layoutColor;
      display: block;
    }

    a:hover {
      font-weight: bold;
      color: variables.$green;
    }

    &.menu-item-active {
      background-color: variables.$green;

      a {
        color: white;
        font-weight: bold;
      }
    }

    .menu-item-submenu {
      background-color: app_variables.$lightGrey;
    }
  }

  .horizontal-menu {
    text-align: right;

    .menu-item-more {
      cursor: pointer;
      height: 66px;
      line-height: 48px;
      float: right;
      display: none;
      position: relative;
    }
  }

  .vertical-menu {
    position: absolute;
    right: 0;
    top: 66px;
    z-index: 2;
    width: auto;

    .menu-item-nav {
      border: 1px solid variables.$grey;
      display: none;
      width: 150px;
      padding: 10px 15px;
      line-height: 18px;
    }
  }

  @media (max-width: 559px) {
    .menu-item-nav {
      width: 28%;
    }

    .menu-item-more {
      width: 16%;
    }

    .horizontal-menu :nth-child(n+4) {
      &.menu-item-nav {
        display: none;
      }

      &.menu-item.menu-item-more {
        display: inline-block;

        .vertical-menu.active :nth-child(n+4) {
          &.menu-item {
            display: block;
          }
        }
      }
    }

    .horizontal-menu :nth-child(4) {
      &.menu-item.menu-item-more {
        display: none;
      }
    }
  }

  @media (min-width: 560px) and (max-width: 799px) {
    .menu-item-nav {
      width: 22%;
    }

    .menu-item-more {
      width: 12%;
    }

    .horizontal-menu :nth-child(n+5) {
      &.menu-item-nav {
        display: none;
      }

      &.menu-item.menu-item-more {
        display: inline-block;

        .vertical-menu.active :nth-child(n+5) {
          &.menu-item {
            display: block;
          }
        }
      }
    }

    .horizontal-menu :nth-child(5) {
      &.menu-item.menu-item-more {
        display: none;
      }
    }
  }

  @media (min-width: 800px) and (max-width: 1009px) {
    .menu-item-nav {
      width: 15.6%;
    }

    .menu-item-more {
      width: 6.4%;
    }

    .horizontal-menu :nth-child(n+7) {
      &.menu-item-nav {
        display: none;
      }

      &.menu-item.menu-item-more {
        display: inline-block;

        .vertical-menu.active :nth-child(n+7) {
          &.menu-item {
            display: block;
          }
        }
      }
    }

    .horizontal-menu :nth-child(7) {
      &.menu-item.menu-item-more {
        display: none;
      }
    }
  }

  @media (min-width: 1010px) and (max-width: 1219px) {
    .menu-item-nav {
      width: 22%;
    }

    .menu-item-more {
      width: 12%;
    }

    .horizontal-menu :nth-child(n+5) {
      &.menu-item-nav {
        display: none;
      }

      &.menu-item.menu-item-more {
        display: inline-block;

        .vertical-menu.active :nth-child(n+5) {
          &.menu-item {
            display: block;
          }
        }
      }
    }

    .horizontal-menu :nth-child(5) {
      &.menu-item.menu-item-more {
        display: none;
      }
    }
  }

  @media (min-width: 1220px) and (max-width: 1399px) {
    .menu-item-nav {
      width: 15.6%;
    }

    .menu-item-more {
      width: 6.4%;
    }

    .horizontal-menu :nth-child(n+7) {
      &.menu-item-nav {
        display: none;
      }

      &.menu-item.menu-item-more {
        display: inline-block;

        .vertical-menu.active :nth-child(n+7) {
          &.menu-item {
            display: block;
          }
        }
      }
    }

    .horizontal-menu :nth-child(7) {
      &.menu-item.menu-item-more {
        display: none;
      }
    }
  }

  @media (min-width: 1400px) and (max-width: 1899px) {
    .menu-item-nav {
      width: 13.7%;
    }

    .menu-item-more {
      width: 4.1%;
    }

    .horizontal-menu :nth-child(n+8) {
      &.menu-item-nav {
        display: none;
      }

      &.menu-item.menu-item-more {
        display: inline-block;

        .vertical-menu.active :nth-child(n+8) {
          &.menu-item {
            display: block;
          }
        }
      }
    }

    .horizontal-menu :nth-child(8) {
      &.menu-item.menu-item-more {
        display: none;
      }
    }
  }

  @media (min-width: 1900px) {
    .menu-item-nav {
      width: 12%;
    }

    .menu-item-more {
      width: 4%;
    }

    .horizontal-menu :nth-child(n+9) {
      &.menu-item-nav {
        display: none;
      }

      &.menu-item.menu-item-more {
        display: inline-block;

        .vertical-menu.active :nth-child(n+9) {
          &.menu-item {
            display: block;
          }
        }
      }
    }

    .horizontal-menu :nth-child(9) {
      &.menu-item.menu-item-more {
        display: none;
      }
    }
  }
}

.shortcut {
  .panel-white {
    height: 160px;
    line-height: 77px;

    @media (min-width: 768px) {
      height: 144px;
    }
  }
}

.lifetime {
  .lifetime-fiche {
    .panel-body {
      .date-responsable {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .lifetime-matching-demandeurs {
    .matching-demandeurs-loading,
    .matching-demandeurs-null {
      margin-top: 10px;
      padding: 15px;
      text-align: center;
      font-size: app_variables.$font-size-large;
    }
  }
}

esk-carte-quartiers {
  #carte-quartiers {
    position: relative;
    height: 100%;

    #actions-carte-quartiers {
      position: absolute;
      top: 5px;
      right: 5px;
      box-shadow: rgba(black, 0.3) 0 1px 4px -1px;
      border-radius: 2px;
      background-color: variables.$whiteSmoke;

      button {
        display: block;
        background: none;
        border: 0;
        border-bottom: 1px solid variables.$grey;
        width: 28px;
        height: 27px;
      }
    }

    svg {
      .quartier {
        cursor: pointer;
      }

      .quartier-hover {
        path,
        polygon,
        polyline {
          fill: app_variables.$azure;
          fill-opacity: 0.5;
        }

        text {
          fill: white;
        }
      }

      .quartier-selected {
        path,
        polygon,
        polyline {
          fill: app_variables.$azure;
        }

        text {
          fill: white;
        }
      }
    }
  }
}

.list-infos {
  .select-all {
    float: left;
    padding-left: 10px;
    margin-top: -10px;
  }
}

.input-group {
  &.input-group-number {
    margin-right: 10px;

    & > input {
      width: 90px;
      float: right;
    }

    & > .input-group-addon {
      width: inherit;
    }

    &.input-group-currency {
      & > input {
        width: 90px;
      }
    }

    &.input-group-area {
      & > input {
        width: 90px;
      }
    }

    &.input-group-date {
      & > input {
        float: left;
      }

      & > div {
        float: left;
      }

      & > span {
        float: left;
      }
    }
  }

  .addon-left {
    font-size: 0.5em;
  }
}

app-bien-edit-viabilisation-assainissement,
app-form-radio-ynn,
soq-input-radio-ynn {
  display: inline-block;
  width: 100%;

  .clip-radio label {
    float: left;
  }
}

.img-container {
  position: relative;
  max-width: 120px;

  .img-tools {
    position: absolute;
    background: rgba(black, 0.3);
    width: 100%;
    height: 35px;
    text-align: right;
    padding-right: 5px;

    span {
      cursor: pointer;
      padding: 5px 5px;
      display: inline-block;
      color: white;
      font-size: app_variables.$font-size-large;
    }
  }
}

.noty-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: calc(#{app_variables.$screen-lg-min} - 30px);

  @media (max-width: app_variables.$screen-md-max) {
    max-width: calc(#{app_variables.$screen-md-min} - 20px);
  }

  @media (max-width: app_variables.$screen-sm-max) {
    max-width: calc(#{app_variables.$screen-sm-min} - 20px);
  }
}

.my-account {
  .crop-photo {
    width: 300px;
  }

  .user-photo {
    width: 300px;

    .thumbnail {
      position: relative;

      .handle {
        position: absolute;
        width: 100%;
        padding-right: 8px;

        .buttons {
          background-color: rgba(black, 0.75);
          text-align: end;
        }
      }
    }
  }

  .account-diffusions {
    .diffusions-diffusion {
      position: relative;

      label {
        width: 250px;
      }

      .diffusion-short-label {
        display: inline-block;
      }

      .diffusion-autre {
        position: absolute;
        top: 0;
        left: 260px;

        input {
          border: none;
          border-bottom: 1px solid variables.$gainsboro;
        }
      }
    }
  }

  .account-dashboard {
    .dashboard-boxes {
      max-width: 700px;
      margin: auto;
      padding-left: 10px;

      @media (max-width: app_variables.$screen-xs-max) {
        padding-left: 0;
      }

      li:first-child {
        border-top: 0;
      }

      li {
        border-top: variables.$border-separator;
        min-height: 60px;

        .box-title {
          padding-top: 20px;
        }
      }
    }
  }
}

soq-input-file {
  border: 1px dashed variables.$grey;
  padding: 10px;
  margin-bottom: 20px;
  display: block;

  &.dnd-active {
    background-color: variables.$grey;
  }

  h1 {
    margin-top: 20px;
  }
}

soq-gmaps-free-draw {
  ui-gmap-google-map {
    .angular-google-map {
      .angular-google-map-container {
        height: 400px;

        .btns-drawing {
          margin: 10px;
          min-width: 400px;
          text-align: center;

          @media (max-width: app_variables.$screen-xs-min) {
            margin-top: 55px;
          }
        }

        .btn-draw {
          background-color: white !important;
        }
      }
    }
  }
}

.ui-sortable {
  .drag-handler {
    cursor: move;
  }

  .drag-handler.handler-precise {
    position: absolute;
  }

  .ui-sortable-placeholder {
    list-style: none;
    min-height: auto;
    background-color: app_variables.$lightGrey;
    padding: 15px;
    height: 50px;

    &.placeholder:after {
      color: variables.$textColor;
      content: "Rel\000E2 cher pour placer \000E0  cette position.";
      position: absolute;
      width: 100%;
      left: unset;
    }
  }
}

.collection-sortable-ul {
  .ui-sortable-placeholder.placeholder {
    min-height: 80px;
    width: calc(100% - 1rem);

    @media (min-width: 768px) {
      width: calc(50% - 1rem);
    }

    @media (min-width: 1280px) {
      width: calc(33% - 1rem);
    }

    @media (min-width: 1536px) {
      width: calc(25% - 1rem);
    }

    &:after {
      color: variables.$textColor;
      content: "Rel\000E2 cher pour placer \000E0  cette position.";
      position: absolute;
      left: unset;
    }
  }
}

.documents {
  @media (min-width: app_variables.$screen-sm-max) {
    .panel-heading {
      min-height: 53px;
    }
  }

  .noty-container {
    padding-top: 40px;

    .documents-statuts {
      margin-bottom: 30px;

      .statuts-explanation {
        .explanation-overload {
          > * {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

.print-button {
  display: inline-block;

  .btn-group.one-menu {
    .btn.btn-dropdown {
      border-radius: 4px;
    }
  }

  .dropdown-menu {
    li {
      padding-top: 5px;
      padding-bottom: 5px;
      border-top: variables.$border-separator;
    }

    li:first-child {
      border-top: 0;
    }
  }
}

.dossier-suivi {
  height: 700px;

  .list-details {
    border-spacing: 0;
    margin: 0 -15px;

    > *, .col-list {
      height: 100%;
      background: white;
    }

    .col-list {
      float: left;
      vertical-align: top;
      width: 360px;
      border-right: variables.$border-separator;

      @media (max-width: app_variables.$screen-xs-max) {
        width: 100%;
        border-right: none;
        position: relative;
      }

      .navigation {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;

        .tabs-container {
          display: inline-block;
        }
      }

      .wrap-list {
        width: 359px;
        height: 100%;
        position: relative;

        @media (max-width: app_variables.$screen-sm-min) {
          width: 100%;
        }
      }
    }

    .details {
      overflow: hidden;
      padding: 0;

      .no-messages {
        min-height: 400px;
      }

      .suivi-email {
        padding: 20px;

        .email-recipients {
          padding-bottom: 10px;
          font-size: app_variables.$font-size-large;
          font-weight: bold;
          border-bottom: 1px solid variables.$textColor;
          cursor: pointer;
        }

        .email-subject {
          font-size: variables.$font-size-extra-large;
          padding: 20px 10px 0;
        }

        .email-message {
          padding: 20px 10px 0;
        }

        .email-attachments {
          margin-top: 20px;

          .email-attachment {
            margin-right: 10px;
            height: 40px;

            a {
              border-radius: 4px;
              border: 1px solid variables.$textColor;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

.search-keywords {
  .input-group {
    max-width: 600px;

    .form-control {
      border-right: 0;
      height: 32px;
    }

    .input-group-btn {
      // @trick, problèmes de calcul de hauteur sur Firefox par rapport à Chrome
      > *,
      .search-filter > button {
        height: 32px;
      }

      .popover {
        height: unset;
      }

      .search-keywords-remove,
      .search-keywords-help {
        border-top: variables.$border-separator;
        border-bottom: variables.$border-separator;
        border-color: variables.$grey;
      }

      .search-keywords-remove {
        padding-top: 6px;
      }

      .search-keywords-help {
        padding-top: 5px;
      }
    }
  }
}

.barre-search {
  .search-keywords {
    max-width: calc(100% - 280px);
    float: left;

    .input-group {
      .input-group-btn {
        .search-filter {
          margin-right: 26px;

          .filter-caret {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
          }

          .dropdown-menu {
            li {
              padding-top: 5px;
              padding-bottom: 5px;
              border-top: variables.$border-separator;
            }

            .filter-item {
              a {
                max-width: 350px;
                padding: 0;

                &:focus,
                &:hover {
                  background-color: transparent;
                }

                > * {
                  display: inline-block;

                  &:focus,
                  &:hover {
                    background-color: variables.$whiteSmoke;
                  }
                }

                .filter-update {
                  position: absolute;
                  right: 0;
                  padding: 3px 13px 3px 13px;
                }

                .filter-select {
                  margin-right: 40px;
                  padding: 3px 10px 3px 20px;
                  width: calc(100% - 40px);
                }
              }
            }

            li:first-child {
              border-top: 0;
            }
          }
        }
      }
    }
  }

  esk-select-sort {
    width: 270px;
    float: left;
    margin-left: 10px;
  }

  @media (max-width: app_variables.$screen-xs-max) {
    .search-keywords {
      max-width: 100%;
    }

    esk-select-sort {
      float: none;
      display: inline-block;
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }
  }
}

.btn-sort {
  border: 0;
  background: 0;
  font-weight: inherit;
}

.configuration {
  .configuration-passerelles {
    .passerelle {
      max-width: 500px;

      @media (min-width: app_variables.$screen-lg-min) {
        margin-left: calc((100% - 500px) / 2);
      }

      .panel-heading {
        height: 60px;

        .media {
          height: 100%;

          .media-left {
            height: 100%;

            .media-object {
              height: 100%;

              img {
                max-height: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

esk-configuration-facebook {
  .popover {
    max-width: 400px;
    width: 400px;

    .passerelle-connect {
      ul {
        li {
          display: inline;
          cursor: pointer;
          margin: 0 5px;
          padding: 3px 10px;
          text-align: center;
        }
      }

      .connect-step {
        margin-top: 15px;

        .step {
          .step-img {
            text-align: center;
          }

          .step-txt {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.noty-intro {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 800px;
  background-image: url("/assets/images/noty/background.jpg");

  .rgba-noty-strong,
  .rgba-noty-strong:after {
    background-color: rgba(variables.$greenNoty, 0.62);
    height: 100%;
  }

  .noty-overlay-effect {
    background: linear-gradient(rgba(white, 0.3), rgba(variables.$greenNoty, 0.5));
    height: 100%;
  }

  .container-login {
    margin-top: 100px;

    .main-login {
      .box-login {
        background: white;
        box-shadow: 0 2px 5px 0 rgba(black, 0.16), 0 2px 10px 0 rgba(black, 0.12);
        border-radius: 5px;
        overflow: hidden;
        padding: 15px;
        margin-top: 15px;

        legend {
          color: variables.$green;
        }

        input {
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          outline: 0;
          box-shadow: none;
          border: none;
          border-bottom: variables.$border-separator;
        }

        label {
          font-size: app_variables.$font-size-large;
        }

        .form-actions:before,
        .form-actions:after {
          content: "";
          display: table;
          line-height: 0;
        }

        .form-actions:after {
          clear: both;
        }

        .form-actions {
          padding-top: 10px;
          display: block;
        }
      }
    }

    .display-4 {
      font-size: app_variables.$multiply-4;
      font-weight: 700;
      line-height: 1.2;
      color: white;
    }
  }
}

.material-btn-primary {
  background-color: variables.$primary;
  color: white;
  box-shadow: 0 2px 5px 0 rgba(black, 0.16), 0 2px 10px 0 rgba(black, 0.12);
  padding: 0.84rem 2.14rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0.375rem;
  border: 0;
  border-radius: 0.125rem;
  cursor: pointer;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;

  &:hover {
    box-shadow: 0 5px 11px 0 rgba(black, 0.18), 0 4px 15px 0 rgba(black, 0.15);
    color: white;
  }
}

esk-footer {
  bottom: 0;
  color: white;
  background-color: app_variables.$layoutColor;
  font-size: app_variables.$font-size-large;
  display: block;

  @media (max-width: app_variables.$screen-xs-max) {
    text-align: center !important;
  }

  .container-footer {
    padding-top: 3rem;

    .row {
      > * {
        margin-bottom: 1.5rem;
      }

      :last-child {
        .col-icon {
          float: left;
          width: 30px;
        }

        .col-info {
          float: left;
          width: calc(100% - 30px);
          margin-bottom: 20px;
        }
      }

      h6 {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.7;
        font-size: variables.$font-size-extra-large;
        height: 46px;
        margin: 0;
      }

      p {
        margin-bottom: 1rem;
      }

      .col-logo {
        img {
          width: 30%;
          max-width: 110px;
        }
      }

      hr {
        border: 0;
        border-top: 1px solid rgba(black, 0.1);
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 1.5rem;
        background-color: variables.$green;
        width: 60px;
      }
    }
  }

  .footer-copyright {
    padding-bottom: 1rem;
    padding-top: 1rem;
    overflow: hidden;
    background-color: rgba(black, 0.2);
    color: hsla(0, 0%, 100%, 0.6);
  }

  a {
    color: white;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

[appProvideParentForm] {
  .ref-public {
    a[uib-popover-template] {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.report-edit {
  .modal-body {
    .drop-zone {
      height: 100px;
      border: 1px dashed variables.$grey;
      padding-top: 30px;
    }
  }
}

.app-start {
  padding: 7%;
  height: 100%;

  @media (max-width: app_variables.$screen-xs-max) {
    margin-top: 0;
  }
}

[ng-transclude]:empty {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.recipient {
  display: inline-block;
  padding: 3px;
}

.pg-list-group {
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: app_variables.$eskLightGrey;

  .pg-list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin: 0 -2px 0 0;
    color: app_variables.$eskDarkGrey;
    text-align: right;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 2px;

    &.active,
    &:hover {
      border-right-color: app_variables.$eskDarkGrey;
      background-color: app_variables.$eskExtraLightGrey;
    }
  }
}

input[type=number] {
  text-align: right;
}

.panel-tools {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: white;
  display: flex;
  height: 100%;
  align-items: center;
}

input.number,
input[soq-only-digits] {
  text-align: right;
}

esk-select-date {
  display: block;

  > * {
    float: left;
  }

  input[uib-datepicker-popup][required] + [uib-datepicker-popup-wrap] {
    .uib-clear {
      display: none;
    }
  }
}

esk-cropper {
  display: block;
  width: 100%;
  height: 100%;

  .esk-cropper-container {
    width: 100%;
    height: 100%;

    &.esk-cropper-hidden {
      visibility: hidden;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

soq-view-crop-photo {
  display: block;
  width: 300px;
  float: left;
  margin: 5px;
  border: 1px solid variables.$green;

  > .preview {
    width: 100%;
    height: 215px;
  }

  > .actions {
    width: 100%;
    padding: 5px;
  }
}

.diagnostic {
  svg {
    max-height: 250px;
  }
}

.popover-email,
.popover-report {
  min-width: 500px;
  max-width: 500px;

  @media (max-width: app_variables.$screen-xs-max) {
    min-width: 60%;
    max-width: 60%;
  }
}

.procedure-edit {
  table {
    tr {
      height: 50px;

      td {
        position: relative;
        padding: 0 15px;
        border: 0;

        &.signataire-nom {
          min-width: 150px;
        }

        &.signataire-prenom {
          min-width: 150px;
        }

        &.signataire-email {
          min-width: 210px;
        }

        &.signataire-telephone {
          min-width: 130px;
        }

        &.signataire-status {
          min-width: 120px;
          text-transform: capitalize;

          .status-date {
            text-transform: lowercase;
            font-size: 10pt;
          }
        }

        &.signataire-actions {
          width: 50px;
        }

        .form-control {
          box-shadow: unset;
          border: 0;
          border-bottom: variables.$border-separator;
          padding: 7px 5px;
          height: 32px;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }

        .error {
          position: absolute;
        }
      }
    }
  }

  .procedure-status {
    display: flex;
    align-items: baseline;
  }

  .modal-footer {
    position: relative;
    min-height: 63px;

    h4 {
      position: absolute;
    }
  }
}
