@use "../../../../variables" as variables;
@use "../variables" as app_variables;

label {
  &:where([class*=tw\:]) {
    margin: 0;
  }
}

// Forms
fieldset {
  background: white;
  border: 1px solid variables.$grey;
  border-radius: 5px;
  margin: 20px 0 20px 0;
  padding: 25px;
  position: relative;

  .form-group {
    margin-left: 0;
    margin-right: 0;
  }

  legend {
    background: inherit;
    color: variables.$primary;
    font-size: app_variables.$font-size-large;
    left: 10px;
    padding: 0 10px;
    position: absolute;
    top: -12px;
    font-weight: 400;
    width: auto !important;
    border: none !important;
  }
}

textarea,
select,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  &:where(:not([class*=tw\:])):where(:not([role="combobox"])) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    background-image: none;
    border: 1px solid variables.$grey;
    border-radius: 0 0 0 0 !important;
    color: app_variables.$textDark;
    font-family: inherit;
    line-height: 1.2;
    font-size: inherit;
    padding: 5px 4px;
    box-shadow: none !important;

    &:hover {
      border-color: app_variables.$darkGrey;
    }

    &:focus {
      box-shadow: none !important;
      border-color: app_variables.$dark05Grey !important;
      background-color: variables.$whiteSmoke !important;
    }
  }
}

textarea[disabled]:where(:not([class*=tw\:])),
input[disabled]:where(:not([class*=tw\:])),
select[disabled]:where(:not([class*=tw\:])) {
  background: transparent !important;
  border-color: variables.$whiteSmoke !important;

  &:hover,
  &:focus {
    background: app_variables.$lightGrey !important;
    border-color: variables.$whiteSmoke;
  }
}

legend {
  color: inherit;
  font-size: app_variables.$font-size-large;
  font-weight: 300;
}

select[multiple="multiple"]:focus option {
  background: variables.$whiteSmoke !important;
}

.has-error .form-control,
.has-warning .form-control {
  &:focus,
  &:hover {
    box-shadow: none;
  }
}

.has-error {
  .error {
    color: variables.$danger;
  }

  input:where([class*=tw\:]),
  .form-control {
    color: app_variables.$textDark;
    border-color: variables.$danger !important;
    border-width: 1px !important;
    border-style: dotted;

    &:focus,
    &:hover {
      border-color: variables.$whiteSmoke;
      border-bottom-color: variables.$danger !important;
    }
  }

  label {
    color: variables.$danger !important;
  }
}

.has-warning {
  color: variables.$warning;

  .form-control {
    color: app_variables.$textDark;
    border-color: variables.$whiteSmoke;
    border-bottom-color: variables.$warning !important;
    border-bottom-width: 1px !important;
    border-bottom-style: dotted;
    border-color: variables.$warning !important;
    border-width: 1px !important;
    border-style: dotted;

    &:focus,
    &:hover {
      border-color: variables.$whiteSmoke;
      border-bottom-color: variables.$warning !important;
    }
  }

  label {
    color: variables.$warning;
  }
}

label {
  font-weight: normal;
  color: app_variables.$labelColored;
}

span.input-icon {
  display: block;
  position: relative;
}

.input-icon > input {
  padding-left: 40px !important;
  padding-right: 6px;
}

.input-icon > .icon {
  bottom: 0;
  color: variables.$primary;
  display: inline-block;
  left: 5px;
  line-height: 35px;
  padding: 0 3px;
  position: absolute;
  top: -1px;
  z-index: 2;
}

select.form-control {
  background-color: white;
  border: 1px solid variables.$grey;
  border-radius: 0 0 0 0;
  color: app_variables.$textDark;
}

select.form-control option {
  padding: 3px 4px;
}

.radio label.radio-inline,
.checkbox label.checkbox-inline {
  display: inline-block;
}

.radio-inline,
.radio-inline + .radio-inline,
.checkbox-inline,
.checkbox-inline + .checkbox-inline {
  margin: 5px 10px 10px 0 !important;
}

.checkbox-table {
  display: inline-block;
  margin: 2px 0 0 0;
  line-height: 10px;
}

.checkbox-table label {
  margin-bottom: 0;
}

.form-group {
  position: relative;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: default;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  cursor: pointer;
  line-height: normal;

  &:where([class*=tw\:]) {
    margin: 0;
  }
}

input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus {
  &:where([class*=tw\:]) {
    outline: unset;
    outline-offset: unset;
  }
}

.clip-check {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;

  label {
    cursor: pointer;
    display: inline-block;
    font-size: variables.$font-size-base;
    margin-right: 15px;
    padding-left: 30px;
    position: relative;
    line-height: 23px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s;
    white-space: nowrap;

    &:before {
      background-color: white;
      border: 1px solid variables.$grey;
      content: "";
      display: inline-block;
      height: 20px;
      left: 0;
      margin-right: 10px;
      position: absolute;
      width: 20px;
      border-radius: 0;
      top: 1px;
      transition: border 0.2s linear 0s, color 0.2s linear 0s;
    }

    &:after {
      display: inline-block;
      font-size: app_variables.$font-size-small;
      height: 19px;
      left: 6px;
      position: absolute;
      top: -1px;
      transition: border 0.2s linear 0s, color 0.2s linear 0s;
      width: 19px;
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      + label:before {
        border-width: 10px;
      }

      + label:after {
        color: white;
        content: "\2713";
      }
    }
  }

  input[type="checkbox"][disabled] {
    + label {
      opacity: 0.65;

      &:before {
        background-color: variables.$whiteSmoke;
      }
    }
  }
}

.clip-check.check-success input[type="checkbox"]:checked + label:before {
  border-color: variables.$success;
}

.clip-check.check-primary input[type="checkbox"]:checked + label:before {
  border-color: variables.$primary;
}

.clip-check.check-warning input[type="checkbox"]:checked + label:before {
  border-color: variables.$warning;
}

.clip-check.check-danger input[type="checkbox"]:checked + label:before {
  border-color: variables.$danger;
}

.clip-check.check-info input[type="checkbox"]:checked + label:before {
  border-color: app_variables.$info;
}

.clip-check.check-purple input[type="checkbox"]:checked + label:before {
  border-color: app_variables.$purple;
}

.clip-check.check-green input[type="checkbox"]:checked + label:before {
  border-color: variables.$green;
}

.clip-radio {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;

  label {
    cursor: pointer;
    display: inline-block;
    margin-right: 15px;
    padding-left: 25px !important;
    position: relative;
    margin-bottom: 6px;

    &:where(:not([class*=tw\:])) {
      font-size: variables.$font-size-base;
      line-height: 23px;
    }

    &:before {
      background-color: white;
      border: 1px solid variables.$grey;
      content: "";
      display: inline-block;
      height: 20px;
      left: 0;
      margin-right: 10px;
      position: absolute;
      width: 20px;
      border-radius: 99px;
      bottom: 2px;
      transition: all 0.3s cubic-bezier(0.455, 0.03, 0.215, 1.33) 0s;
    }

    &:after {
      border: 1px solid variables.$grey;
      content: "";
      display: inline-block;
      height: 20px;
      left: 0;
      margin-right: 10px;
      position: absolute;
      width: 20px;
      border-radius: 99px;
      bottom: 2px;
      transition: all 0.3s cubic-bezier(0.455, 0.03, 0.215, 1.33) 0s;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked {
      + label:before {
        border-width: 6px;
        background: variables.$whiteSmoke;
        border-color: white;
      }
    }
  }

  input[type="radio"][disabled] + label {
    opacity: 0.65;
  }

  &.radio-md {
    label {
      padding-left: 30px !important;
      line-height: 28px;

      &:before {
        height: 25px;
        width: 25px;
      }

      &:after {
        height: 25px;
        width: 25px;
      }
    }

    input[type="radio"] {
      display: none;

      &:checked {
        + label:before {
          border-width: 7px;
        }
      }
    }
  }

  &.radio-lg {
    label {
      padding-left: 35px !important;
      line-height: 33px;

      &:before {
        height: 30px;
        width: 30px;
      }

      &:after {
        height: 30px;
        width: 30px;
      }
    }

    input[type="radio"] {
      display: none;

      &:checked {
        + label:before {
          border-width: 8px;
        }
      }
    }
  }
}

.clip-radio.radio-success input[type="radio"]:checked + label:before {
  background: variables.$success;
}

.clip-radio.radio-primary input[type="radio"]:checked + label:before {
  background: variables.$primary;
}

.clip-radio.radio-info input[type="radio"]:checked + label:before {
  background: app_variables.$info;
}

.clip-radio.radio-warning input[type="radio"]:checked + label:before {
  background: variables.$warning;
}

.clip-radio.radio-danger input[type="radio"]:checked + label:before {
  background: variables.$danger;
}

.clip-radio.radio-purple input[type="radio"]:checked + label:before {
  background: app_variables.$purple;
}

.clip-radio.radio-green input[type="radio"]:checked + label:before {
  background: variables.$green;
}
